import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";

import { Show } from "../../models/Shows";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { LoggedScoresReturn } from "../../models/ScoringLog";
import { QuestionAnswerCast } from "../../models/Questions";
import { LeagueUser } from "../../models/LeagueUsers";
import { QuestionAnswers } from "../../models/QuestionAnswers";

import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { TableSkeleton } from "../atoms/Skeletons";
import SubmitButton from "../atoms/SubmitButton";
import LogScoresEpisodeSelect from "../atoms/LogScoresEpisodeSelect";
import LogScoresAccordion from "../molecules/LogScoresAccordion";
import PageTemplate from "../templates/PageTemplate";
import LogQuestionsAccordion from "../molecules/LogQuestions";

const validationSchema = yup.object({
    log: yup.array().of(
        yup.object().shape({
            id: yup.number(),
            name: yup.string(),
            eliminated: yup.boolean(),
            scores: yup.array().of(
                yup.object().shape({
                    id: yup.number(),
                    description: yup.string(),
                    amount: yup.number(),
                    value: yup.number(),
                })
            ),
        })
    ),
});

interface ParamTypes {
    showId: string;
    leagueId: string;
    episodeParam?: string;
}

const CustomLeagueLogScores: React.FC = () => {
    const history = useHistory();
    const { showId, leagueId, episodeParam } = useParams<ParamTypes>();

    const [show, setShow] = useState<Show>();
    const [loading, setLoading] = useState<boolean>(true);
    const [log, setLog] = useState<LoggedScoresReturn[]>([]);
    const [questions, setQuestions] = useState<QuestionAnswers[]>([]);
    const episode = episodeParam ? parseInt(episodeParam) : 0;

    //could send through type to see if we need to grab custom or regular
    const [submitting, setSubmitting] = useState<boolean>(false);

    const showRequest = useApi(apiRoutes.GET_SHOW(showId), {
        responseKey: "show",
        onSuccess: (response: Show) => {
            setShow(response);
            setLoading(false);
        },
        onFailure: () => {
            setLoading(false);
        },
    });
    const leagueRequest = useApi(apiRoutes.GET_LEAGUE(leagueId), {
        onSuccess: (response: { show: Show; leagueUser: LeagueUser }) => {
            if (!response.leagueUser.commissioner) {
                window.location.href = "/";
            }
        },
        onFailure: (message: string) => {
            setLoading(false);
        },
    });
    const loggedScoresRequest = useApi(apiRoutes.GET_LOGGED_SCORES(showId), {
        onSuccess: (response: {
            cast: LoggedScoresReturn[];
            questions: QuestionAnswers[];
        }) => {
            setLog(response.cast);
            setQuestions(response.questions);
            setLoading(false);
        },
        onFailure: () => {
            setLoading(false);
        },
    });
    const saveLoggedScoresRequest = useApi(apiRoutes.SAVE_LOGGED_SCORES(), {
        responseKey: "message",
        onSuccess: (message: string) => {
            toast.success(message);
            setSubmitting(false);
            history.push(`/myleagues/view/${leagueId}`);
        },
        onFailure: () => {
            setSubmitting(false);
        },
    });

    useEffect(() => {
        leagueRequest.request();
        showRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [episode]);

    useEffect(() => {
        if (episode > 0 && log.length === 0) {
            loggedScoresRequest.request({ episode });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [episode, log]);

    const initialValues = {
        log: log,
        questions,
    };

    return (
        <PageTemplate
            header="Log Scores"
            headerSecondary={show && show.show}
            loading={loading}
            skeleton={<TableSkeleton size={2} />}
        >
            {show && (
                <>
                    <div className="mb-3">
                        <Link to={`/myleagues/view/${leagueId}`}>
                            <Button variant="light">Back</Button>
                        </Link>
                    </div>
                    <LogScoresEpisodeSelect
                        customLeague
                        leagueId={leagueId}
                        show={show}
                        episode={episode}
                    />
                    {episode > 0 && log.length > 0 && (
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values, { resetForm }) => {
                                setSubmitting(true);
                                saveLoggedScoresRequest.request({
                                    data: values.log,
                                    questions: values.questions,
                                    episode: episode,
                                    show_id: show.id,
                                });
                            }}
                        >
                            {({
                                values,
                                handleSubmit,
                                isValid,
                                setFieldValue,
                            }) => {
                                return (
                                    <Form
                                        onSubmit={(e) => {
                                            if (!isValid) {
                                                //toast.error("Please fix the errors");
                                            }
                                            handleSubmit(e);
                                        }}
                                    >
                                        {values.questions.length > 0 && (
                                            <LogQuestionsAccordion
                                                cast={log}
                                                questions={values.questions}
                                                setValues={(
                                                    list: QuestionAnswerCast[],
                                                    questionId: number
                                                ) => {
                                                    const newQuestions =
                                                        values.questions.map(
                                                            (q) =>
                                                                q.question_id ===
                                                                questionId
                                                                    ? {
                                                                          ...q,
                                                                          answers:
                                                                              list,
                                                                      }
                                                                    : { ...q }
                                                        );
                                                    setFieldValue(
                                                        "questions",
                                                        newQuestions
                                                    );
                                                }}
                                            />
                                        )}
                                        <LogScoresAccordion
                                            values={values}
                                            log={log}
                                        />
                                        <div className="text-center">
                                            <SubmitButton
                                                title="Submit Scores"
                                                submitting={submitting}
                                            />
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    )}
                </>
            )}
        </PageTemplate>
    );
};

export default CustomLeagueLogScores;

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Avatar from "@mui/material/Avatar";

import { RootState } from "../../store";
import { imageUrl } from "../../config";
import useApi from "../../hooks/useApi";
import {
    DraftRecap as DraftRecapModel,
    DraftRecapResponse,
} from "../../models/Draft";
import { League } from "../../models/Leagues";
import apiRoutes from "../../services/apiRoutes";

import { TableSkeleton } from "../atoms/Skeletons";
import PageTemplate from "../templates/PageTemplate";
import RoundHeader from "../atoms/RoundHeader";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";
import { LeagueTableStyled } from "../atoms/styles/Table.styled";
import { Row } from "react-bootstrap";
import CastTeam from "../molecules/CastTeam";

interface ParamTypes {
    id: string;
}

const DraftRecap: React.FC = () => {
    const { id } = useParams<ParamTypes>();
    const history = useHistory();
    const subscription = useSelector((state: RootState) => state.subscription);
    const [loading, setLoading] = useState<boolean>(true);
    const [league, setLeague] = useState<League>();
    const [recap, setRecap] = useState<DraftRecapModel[]>([]);
    const [leagueUserCount, setLeagueUserCount] = useState<number>(0);

    const draftRecapRequest = useApi(apiRoutes.GET_DRAFT_RECAP(id), {
        onSuccess: (response: DraftRecapResponse) => {
            setLeague(response.league);
            setRecap(response.recap);
            setLeagueUserCount(response.leagueUserCount);
            setLoading(false);
        },
        onFailure: () => {
            setLoading(false);
        },
    });

    useEffect(() => {
        draftRecapRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const determineRound = (value: DraftRecapModel, index: number) => {
        const position = index + 1;
        if (position === 1) {
            return <RoundHeader round={1} />;
        } else {
            const round = Math.ceil(position / leagueUserCount);
            const remainder = position % leagueUserCount;
            if (remainder === 1) {
                return <RoundHeader round={round} />;
            } else {
                return null;
            }
        }
    };

    return (
        <PageTemplate
            header="Draft Recap"
            headerSecondary={league && league.name}
            loading={loading}
            skeleton={<TableSkeleton size={2} />}
        >
            <Button
                className="mb-4"
                variant="light"
                onClick={() =>
                    history.push(
                        `/myleagues/view/${league.id}${
                            league.id === "0" ||
                            league.id === 0 ||
                            league.id === "team" ||
                            league.id === "budget"
                                ? `/${league.show_id}`
                                : ""
                        }`
                    )
                }
            >
                Back
            </Button>
            {!loading && recap.length === 0 && (
                <div className="text-center">
                    There's no draft recap for this league.
                </div>
            )}
            {!loading && recap.length > 0 && (
                <LeagueTableStyled>
                    <div className="header font-weight-bold p-3">
                        <Row className="header">
                            <Col>Pick</Col>
                            <Col className="pl-4">Player</Col>
                            {subscription &&
                                hasPremiumSub(subscription.name) && (
                                    <Col className="text-center">
                                        Avg Draft Position
                                    </Col>
                                )}
                            <Col className="text-center">Team</Col>
                        </Row>
                    </div>
                    <div className="tbody">
                        {recap.map((value, index) => {
                            return (
                                <>
                                    {determineRound(value, index)}
                                    <Row
                                        className="p-3 align-items-center"
                                        key={value.id}
                                    >
                                        <Col>{value.draft_position}</Col>
                                        <Col>
                                            <Col>
                                                <Avatar
                                                    style={{
                                                        width: 60,
                                                        height: 60,
                                                    }}
                                                    src={
                                                        value.pic
                                                            ? `${imageUrl}${value.pic}`
                                                            : ""
                                                    }
                                                />
                                                <div className="text-left">
                                                    {value.name}
                                                </div>
                                                <CastTeam
                                                    left
                                                    size={10}
                                                    team={value.cast.team}
                                                    partner={value.cast.partner}
                                                />
                                            </Col>
                                        </Col>
                                        {subscription &&
                                            hasPremiumSub(
                                                subscription.name
                                            ) && (
                                                <Col className="text-center">
                                                    {
                                                        value.average_draft_position
                                                    }
                                                </Col>
                                            )}
                                        <Col className="text-center">
                                            {value.team_name}
                                        </Col>
                                    </Row>
                                </>
                            );
                        })}
                    </div>
                </LeagueTableStyled>
            )}
        </PageTemplate>
    );
};

export default DraftRecap;

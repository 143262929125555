import React from "react";
import { FormikErrors } from "formik";
import { useSelector } from "react-redux";

import { RootState } from "../../store";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

import { CastItem, ScoreItem } from "../pages/EditCustomLeague";

import { Small } from "../atoms";
import CustomCastInfoForm from "./CustomCastInfoForm";
import CustomLeagueInfoForm from "./CustomLeagueInfoForm";
import CustomScoringInfoForm from "./CustomScoringInfoForm";
import CustomShowInfoForm from "./CustomShowInfoForm";
import { ShowFormFields } from "../pages/CreateCustomShow";
import ChallengeQuestionForm from "./ChallengeQuestionForm";
import { ChallengeQuestion } from "../../models/ChallengeQuestions";

interface CustomLeagueFormProps {
    values: ShowFormFields;
    errors: FormikErrors<ShowFormFields>;
    edit?: boolean;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
    ) => void;
    canAddCast?: boolean;
    canAddScoring?: boolean;
    canAddQuestion?: boolean;
}

const CustomLeagueForm: React.FC<CustomLeagueFormProps> = ({
    values,
    errors,
    edit,
    setFieldValue,
    canAddCast,
    canAddScoring,
    canAddQuestion,
}) => {
    const user = useSelector((state: RootState) => state.user);

    const addCast = (
        cast: CastItem[],
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean
        ) => void
    ) => {
        const newCast = {
            reference: `${user.id}custom${values.cast.length + 1}`,
            first_name: "",
            last_name: "",
            gender: "N/A",
            rookie: false,
            pic: undefined,
        };
        setFieldValue("cast", [newCast, ...cast]);
    };

    const addScoring = (
        scoring: ScoreItem[],
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean
        ) => void
    ) => {
        const newScoring = {
            description: undefined,
            category: "",
            score: 0,
        };
        setFieldValue("scoring", [newScoring, ...scoring]);
    };

    const addQuestion = (
        questions: ChallengeQuestion[],
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean
        ) => void
    ) => {
        const newQuestion = {
            description: undefined,
            type: "Episode",
            score: 0,
        };
        setFieldValue("questions", [newQuestion, ...questions]);
    };

    return (
        <>
            <h3 className="mt-4">Show Info</h3>
            <CustomShowInfoForm values={values} setFieldValue={setFieldValue} />
            <h3>League Info</h3>
            <CustomLeagueInfoForm edit={edit ? edit : false} values={values} />
            <h3 className="mt-4">
                Cast{" "}
                {canAddCast && (
                    <Fab
                        style={{
                            background: "#198754",
                            color: "#fff",
                        }}
                        size="small"
                        onClick={() => addCast(values.cast, setFieldValue)}
                    >
                        <AddIcon />
                    </Fab>
                )}
            </h3>
            {!edit && (
                <Small>Cast can be edited after the league is created</Small>
            )}
            {values.cast.length > 0 &&
                values.cast.map((player, index) => {
                    return (
                        <React.Fragment key={index}>
                            <CustomCastInfoForm
                                key={index}
                                values={values}
                                index={index}
                                player={player}
                                setFieldValue={setFieldValue}
                            />
                            <Divider className="mt-3 mb-3" />
                        </React.Fragment>
                    );
                })}
            <h3 className="mt-4">
                Challenge Questions{" "}
                {canAddQuestion && (
                    <Fab
                        style={{
                            background: "#198754",
                            color: "#fff",
                        }}
                        size="small"
                        onClick={() =>
                            addQuestion(values.questions, setFieldValue)
                        }
                    >
                        <AddIcon />
                    </Fab>
                )}
            </h3>
            {!edit && (
                <Small>
                    Challenge Questions can be edited after the league is
                    created
                </Small>
            )}
            {values.questions.length > 0 &&
                values.questions.map((question, index) => {
                    return (
                        <ChallengeQuestionForm
                            key={index}
                            values={values}
                            setFieldValue={setFieldValue}
                            index={index}
                            question={question}
                            custom
                        />
                    );
                })}
            {values.draftType !== "Confidence Pool" &&
                values.draftType !== "Eliminated Confidence Pool" && (
                    <>
                        <h3 className="mt-4">
                            Scoring{" "}
                            {canAddScoring && (
                                <Fab
                                    style={{
                                        background: "#198754",
                                        color: "#fff",
                                    }}
                                    size="small"
                                    onClick={() =>
                                        addScoring(
                                            values.scoring,
                                            setFieldValue
                                        )
                                    }
                                >
                                    <AddIcon />
                                </Fab>
                            )}
                        </h3>
                        {!edit && (
                            <Small>
                                Scoring Fields can be edited after the league is
                                created
                            </Small>
                        )}
                        {values.scoring.length > 0 &&
                            values.scoring.map((score, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <CustomScoringInfoForm
                                            key={index}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            index={index}
                                            score={score}
                                        />
                                        <Divider className="mt-3 mb-3" />
                                    </React.Fragment>
                                );
                            })}
                    </>
                )}
        </>
    );
};

export default CustomLeagueForm;

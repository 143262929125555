import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";

import Form from "react-bootstrap/Form";

import { DraftTypes, Show } from "../../models/Shows";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { League } from "../../models/Leagues";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { LeagueScoring } from "../../models/LeagueScoring";
import { Cast } from "../../models/Cast";
import { validGenderLimits, validRookieLimit } from "../../helpers/limitChecks";

import { Small } from "../atoms";
import SubmitButton from "../atoms/SubmitButton";
import { ShowFormFields } from "./CreateCustomShow";
import CustomLeagueForm from "../organisms/CustomLeagueForm";
import PageTemplate from "../templates/PageTemplate";
import { ArticleTextSkeleton } from "../atoms/Skeletons";
import AreYouSureModal from "../atoms/AreYouSureModal";
import { Button } from "react-bootstrap";
import { ChallengeQuestion } from "../../models/ChallengeQuestions";

export interface CastItem {
    id?: number;
    reference: string;
    first_name: string;
    last_name: string;
    pic: string;
}

export interface ScoreItem {
    id?: number;
    description: string;
    category: string;
    score: number;
}

interface ParamTypes {
    id: string;
}

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const validationSchema = yup.object({
    leagueName: yup.string().required("League Name is required"),
    draftType: yup.string().required("Draft Type is required"),
    showName: yup.string().required("Show Name is required"),
    showImage: yup.mixed().required("Show Image is required"),
    showDay: yup.string().required("Day is required"),
    showHour: yup.number().required("Hour is required"),
    showMin: yup.number().required("Minute is required"),
    dateMonth: yup.string().required("Month is required"),
    dateDay: yup.number().required("Day is required"),
    dateYear: yup.string().required("Year is required"),
    playersPerWeek: yup.number().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Weekly Redraft",
        then: yup.number().required("Players Per Week is required"),
        otherwise: yup.number().nullable(),
    }),
    playerLimit: yup.mixed().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Weekly Redraft",
        then: yup.mixed().required("Player Limit is required").nullable(),
        otherwise: yup.mixed().nullable(),
    }),
    playersPerTeam: yup.number().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Team",
        then: yup.number().required("Players Per Team is required").nullable(),
        otherwise: yup.number().nullable(),
    }),
    startersPerTeam: yup.number().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Team",
        then: yup
            .number()
            .required("Starters Per Team is required")
            .test(
                "starters_less_than_players",
                "Starters Per Team must be less than or equal to Players Per Team",
                function (value) {
                    const { playersPerTeam } = this.parent;
                    if (value) {
                        if (playersPerTeam < value) {
                            return false;
                        }
                    }
                    return true;
                }
            )
            .nullable(),
        otherwise: yup.number().nullable(),
    }),
    rookieLimit: yup.mixed().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Team",
        then: yup.mixed().nullable(),
        otherwise: yup.mixed().nullable(),
    }),
    maleLimit: yup.mixed().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Team",
        then: yup.mixed().nullable(),
        otherwise: yup.mixed().nullable(),
    }),
    femaleLimit: yup.mixed().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Team",
        then: yup.mixed().nullable(),
        otherwise: yup.mixed().nullable(),
    }),
    allowTrading: yup.boolean().optional(),
    swaps: yup.mixed().optional(),
    lastSwapEp: yup.mixed().optional(),
    cast: yup.array().of(
        yup.object().shape({
            first_name: yup.string(),
            last_name: yup.string(),
            pic: yup.mixed(),
            rookie: yup.boolean().optional(),
            gender: yup.string().optional().nullable(),
        })
    ),
    scores: yup.array().of(
        yup.object().shape({
            description: yup.string(),
            category: yup.string(),
            score: yup.number(),
        })
    ),
    questions: yup.array().of(
        yup.object().shape({
            description: yup.string(),
            type: yup.string(),
            score: yup
                .string()
                .matches(/^-?\d*$/g, "Invalid Score")
                .required("Invalid Score"),
        })
    ),
});

interface CreateCustomResponse {
    league: League;
    show: Show;
    message: string;
}

const EditCustomLeague: React.FC = () => {
    const { id } = useParams<ParamTypes>();
    const history = useHistory();
    const [league, setLeague] = useState<League>();
    const [show, setShow] = useState<Show>();
    const [castCount, setCastCount] = useState<number>(0);
    const [scoresCount, setScoresCount] = useState<number>(0);
    const [questionsCount, setQuestionsCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [startersVisible, setStartersVisible] = useState<boolean>(false);
    const [weeklyVisible, setWeeklyVisible] = useState<boolean>(false);
    const [scoreChangeVisible, setScoreChangeVisible] =
        useState<boolean>(false);

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState<ShowFormFields>({
        leagueName: "",
        draftType: "Weekly Redraft",
        playersPerWeek: 1,
        playersPerTeam: 2,
        startersPerTeam: 1,
        playerLimit: "No Limit",
        maleLimit: "No Limit",
        femaleLimit: "No Limit",
        rookieLimit: "No Limit",
        teamName: "",
        showName: "",
        showImage: undefined,
        showDay: "Sunday",
        showHour: 0,
        showMin: 0,
        dateMonth: "January",
        dateDay: 1,
        dateYear: new Date().getFullYear(),
        allowTrading: true,
        genderLimits: false,
        rookieLimits: false,
        cast: [],
        scoring: [],
        swaps: null,
        lastSwapEp: null,
        questions: [],
    });

    const saveCustomLeagueRequest = useApi(apiRoutes.SAVE_CUSTOM_LEAGUE(id), {
        onSuccess: (response: CreateCustomResponse) => {
            setSubmitting(false);
            toast.success(response.message);
            history.push(`/myleagues/view/${response.league.id}`);
        },
        onFailure: (message: string) => {
            setSubmitting(false);
        },
    });
    const deleteLeagueRequest = useApi(apiRoutes.DELETE_LEAGUE(id), {
        onSuccess: (response: string) => {
            toast.success(response);
            setDeleting(false);
            history.push("/myleagues");
        },
        onFailure: () => {
            setDeleting(false);
        },
        responseKey: "message",
    });
    const leagueRequest = useApi(apiRoutes.GET_LEAGUE(id), {
        onSuccess: onLeagueLoaded,
    });

    const getCustomLeagueValues = (leagueType: number) => {
        if (leagueType === 3) {
            return [50, 50, 5];
        } else if (leagueType === 2) {
            return [30, 15, 3];
        } else {
            return [15, 5, 1];
        }
    };

    function onLeagueLoaded(response: {
        league: League;
        leagueUser: LeaguesLeagueUser;
        scoring: LeagueScoring[];
        show: Show;
        canViewLeague: boolean;
        cast: Cast[];
        questions: ChallengeQuestion[];
    }) {
        if (response.canViewLeague && response.leagueUser.commissioner) {
            const leagueValues = getCustomLeagueValues(
                response.show.custom_type
            );
            setCastCount(leagueValues[0]);
            setScoresCount(leagueValues[1]);
            setQuestionsCount(leagueValues[2]);

            const cast = response.cast.map((c) => ({
                ...c,
                reference: `${c.show_id}custom${c.id}`,
            }));
            const scoring = response.scoring;
            setInitialValues({
                leagueName: response.league.name,
                draftType: response.league.draft_type,
                playersPerWeek: response.league.players_per_team,
                playersPerTeam: response.league.players_per_team,
                startersPerTeam: response.league.starters_per_week,
                playerLimit: response.league.player_limit
                    ? response.league.player_limit
                    : "No Limit",
                showName: response.show.show,
                showImage: response.show.img,
                showDay: response.show.day_of_week,
                showHour: response.show.show_time,
                showMin: response.show.minute,
                dateDay: response.show.day ? response.show.day : 1,
                dateMonth: response.show.month
                    ? months[response.show.month - 1]
                    : "January",
                dateYear: response.show.year
                    ? response.show.year
                    : new Date().getFullYear(),
                allowTrading: response.league.allow_trading,
                genderLimits: response.show.gender_limit,
                rookieLimits: response.show.rookie_limit,
                maleLimit: response.league.male_limit,
                femaleLimit: response.league.female_limit,
                rookieLimit: response.league.rookie_limit,
                cast: [...cast],
                scoring: [...scoring],
                swaps: response.league.swaps,
                lastSwapEp: response.league.last_swap_ep,
                questions: response.questions,
            });
            setLeague(response.league);
            setShow(response.show);
            setLoading(false);
        } else {
            window.location.href = "/";
        }
    }

    useEffect(() => {
        leagueRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageTemplate
            loading={loading}
            skeleton={<ArticleTextSkeleton size={3} />}
            header="Edit Custom League"
            headerSecondary={league && league.name}
        >
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={(values, { setFieldError }) => {
                    const maleLimit =
                        values.maleLimit !== null
                            ? values.maleLimit.toString()
                            : "No Limit";
                    const femaleLimit =
                        values.femaleLimit !== null
                            ? values.femaleLimit.toString()
                            : "No Limit";
                    const rookieLimit =
                        values.rookieLimit !== null
                            ? values.rookieLimit.toString()
                            : "No Limit";
                    if (
                        values.draftType !== "Team" ||
                        (validGenderLimits(
                            maleLimit === "No Limit" ? 0 : parseInt(maleLimit),
                            femaleLimit === "No Limit"
                                ? 0
                                : parseInt(femaleLimit),
                            values.playersPerTeam
                        ) &&
                            validRookieLimit(
                                rookieLimit === "No Limit"
                                    ? 0
                                    : parseInt(rookieLimit),
                                values.playersPerTeam
                            ))
                    ) {
                        setFieldError("maleLimit", undefined);
                        setFieldError("femaleLimit", undefined);
                        setFieldError("rookieLimit", undefined);
                        setSubmitting(true);
                        const headers = {
                            "content-type": "multipart/form-data",
                        };
                        const data = new FormData();
                        data.append("leagueName", values.leagueName);
                        data.append("draftType", values.draftType);
                        data.append("showName", values.showName);
                        data.append("showImage", values.showImage);
                        data.append("showDay", values.showDay);
                        data.append("showHour", values.showHour.toString());
                        data.append("showMin", values.showMin.toString());
                        data.append("month", values.dateMonth);
                        data.append("day", values.dateDay.toString());
                        data.append("year", values.dateYear.toString());
                        data.append("cast", JSON.stringify(values.cast));
                        data.append("scoring", JSON.stringify(values.scoring));
                        data.append(
                            "questions",
                            JSON.stringify(values.questions)
                        );
                        data.append("show_id", show.id.toString());
                        data.append("maleLimit", maleLimit);
                        data.append("femaleLimit", femaleLimit);
                        data.append("rookieLimit", rookieLimit);
                        data.append(
                            "allowTrading",
                            values.allowTrading.toString()
                        );
                        data.append(
                            "gender_limit",
                            values.genderLimits.toString()
                        );
                        data.append(
                            "rookie_limit",
                            values.rookieLimits.toString()
                        );
                        if (
                            values.draftType === "Weekly Redraft" ||
                            values.draftType === "Weekly Budget"
                        ) {
                            if (values.draftType === "Weekly Redraft") {
                                data.append(
                                    "playersPer",
                                    values.playersPerWeek.toString()
                                );
                            }
                            data.append(
                                "playerLimit",
                                values.playerLimit.toString()
                            );
                        } else if (values.draftType === "Team") {
                            data.append(
                                "playersPer",
                                values.playersPerTeam.toString()
                            );
                            data.append(
                                "startersPerWeek",
                                values.startersPerTeam.toString()
                            );
                            if (values.swaps)
                                data.append("swaps", values.swaps.toString());
                            if (values.lastSwapEp)
                                data.append(
                                    "lastSwapEp",
                                    values.lastSwapEp.toString()
                                );
                        }
                        values.cast.forEach((cast) => {
                            if (typeof cast.pic === "object") {
                                if (cast.pic !== null) {
                                    data.append(
                                        `castImg-${cast.reference}`,
                                        cast.pic
                                    );
                                } else {
                                    data.append(
                                        `castImgId-${cast.id}`,
                                        cast.pic
                                    );
                                }
                            }
                        });
                        saveCustomLeagueRequest.request(data, headers);
                    } else {
                        if (
                            values.draftType === "Team" &&
                            !validGenderLimits(
                                maleLimit === "No Limit"
                                    ? 0
                                    : parseInt(maleLimit),
                                femaleLimit === "No Limit"
                                    ? 0
                                    : parseInt(femaleLimit),
                                values.playersPerTeam
                            )
                        ) {
                            setFieldError(
                                "maleLimit",
                                "Male Limit and Female Limit cannot combine to be more than Players Per Team"
                            );
                            setFieldError(
                                "femaleLimit",
                                "Male Limit and Female Limit cannot combine to be more than Players Per Team"
                            );
                            toast.error(
                                "Male Limit and Female Limit cannot combine to be more than Players Per Team"
                            );
                        }
                        if (
                            values.draftType === "Team" &&
                            !validRookieLimit(
                                values.rookieLimit === "No Limit"
                                    ? 0
                                    : parseInt(values.rookieLimit.toString()),
                                values.playersPerTeam
                            )
                        ) {
                            setFieldError(
                                "rookieLimit",
                                "Rookie Limit cannot be more than Players Per Team"
                            );
                            toast.error(
                                "Rookie Limit cannot be more than Players Per Team"
                            );
                        }
                    }
                }}
            >
                {({ handleSubmit, isValid, values, errors, setFieldValue }) => {
                    return (
                        <>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    if (!isValid) {
                                        toast.error("Please fix the errors");
                                    }
                                    if (
                                        JSON.stringify(values.scoring) !==
                                        JSON.stringify(initialValues.scoring)
                                    ) {
                                        setScoreChangeVisible(true);
                                    } else if (
                                        league.draft_type === "Team" &&
                                        values.startersPerTeam &&
                                        values.startersPerTeam !==
                                            league.starters_per_week
                                    ) {
                                        setStartersVisible(true);
                                    } else if (
                                        league.draft_type ===
                                            "Weekly Redraft" &&
                                        values.playersPerWeek &&
                                        values.playersPerWeek !==
                                            league.players_per_team
                                    ) {
                                        setWeeklyVisible(true);
                                    } else {
                                        handleSubmit(e);
                                    }
                                }}
                            >
                                <CustomLeagueForm
                                    values={values}
                                    errors={errors}
                                    edit
                                    setFieldValue={setFieldValue}
                                    canAddCast={values.cast.length < castCount}
                                    canAddScoring={
                                        values.scoring.length < scoresCount
                                    }
                                    canAddQuestion={
                                        values.questions.length < questionsCount
                                    }
                                />
                                <Small>
                                    * Saving may take a while due to image
                                    uploades. Please wait a couple minutes.
                                </Small>
                                <div className="mt-4 text-center">
                                    <SubmitButton
                                        className="mr-2"
                                        title="Save Custom League"
                                        submitting={submitting}
                                    />
                                    <Button
                                        className="mr-2"
                                        onClick={() => setShowDelete(true)}
                                        variant="danger"
                                        disabled={submitting || deleting}
                                    >
                                        Delete League
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            history.push(
                                                `/myleagues/view/${league.id}${
                                                    league.id === "0" ||
                                                    league.id === 0 ||
                                                    league.id === "team" ||
                                                    league.id === "budget"
                                                        ? `/${league.show_id}`
                                                        : ""
                                                }`
                                            )
                                        }
                                        variant="light"
                                        disabled={submitting}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                            <AreYouSureModal
                                title="Update Scoring Settings"
                                submitting={submitting}
                                submitText={"Saving"}
                                showModal={scoreChangeVisible}
                                body={`Adjusting the scoring settings will recalculate all previous and upcoming scores in your league. Do you want to continue?`}
                                setShowModal={setScoreChangeVisible}
                                onSuccess={handleSubmit}
                                primaryButton="Save"
                            />
                            <AreYouSureModal
                                title="Update Starters Per Team"
                                submitting={submitting}
                                submitText={"Saving"}
                                showModal={startersVisible}
                                body={`You're adjusting starters per week from ${
                                    league.starters_per_week
                                } to ${values.startersPerTeam}. ${
                                    values.startersPerTeam <
                                    league.starters_per_week
                                        ? `This will move ${
                                              league.starters_per_week -
                                              values.startersPerTeam
                                          } starter(s) from each team in your league to their bench.`
                                        : ``
                                }${
                                    values.startersPerTeam >
                                    league.starters_per_week
                                        ? `This will move ${
                                              values.startersPerTeam -
                                              league.starters_per_week
                                          } bench player(s) from each team in your league to starting spots.`
                                        : ``
                                } Please inform your league members to check their lineups. Continue with this change?`}
                                setShowModal={setStartersVisible}
                                onSuccess={handleSubmit}
                                primaryButton="Save"
                            />
                            <AreYouSureModal
                                title="Update Players Per Week"
                                submitting={submitting}
                                submitText={"Saving"}
                                showModal={weeklyVisible}
                                body={`You're adjusting players per week from ${
                                    league.players_per_team
                                } to ${values.playersPerWeek}. ${
                                    values.playersPerWeek <
                                    league.players_per_team
                                        ? `This will remove ${
                                              league.players_per_team -
                                              values.playersPerWeek
                                          } contestant(s) from each team's selection in your league this week.`
                                        : ``
                                }${
                                    values.playersPerWeek >
                                    league.players_per_team
                                        ? `This will add ${
                                              values.playersPerWeek -
                                              league.players_per_team
                                          } blank spot(s) to each team's selections in your league this week.`
                                        : ``
                                } Please inform your league members to check their lineups. Continue with this change?`}
                                setShowModal={setWeeklyVisible}
                                onSuccess={handleSubmit}
                                primaryButton="Save"
                            />
                        </>
                    );
                }}
            </Formik>
            <AreYouSureModal
                showModal={showDelete}
                setShowModal={setShowDelete}
                body={`Are you sure you want to delete this league? This cannot be undone!`}
                onSuccess={() => {
                    setDeleting(true);
                    deleteLeagueRequest.request({ custom: true });
                }}
                submitText="Deleting"
                submitting={deleting}
            />
        </PageTemplate>
    );
};

export default EditCustomLeague;

import React from "react";
import { useTheme } from "styled-components";
import Multiselect from "multiselect-react-dropdown";

import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";

import { LoggedScoresReturn } from "../../models/ScoringLog";
import { QuestionAnswerCast } from "../../models/Questions";
import { QuestionAnswers } from "../../models/QuestionAnswers";

import { CardStyled, FormGroupWrapper } from "../atoms";

interface LogQuestionsAccordionProps {
    questions: QuestionAnswers[];
    cast: LoggedScoresReturn[];
    setValues: (list: QuestionAnswerCast[], questionId: number) => void;
}

const LogQuestionsAccordion: React.FC<LogQuestionsAccordionProps> = ({
    questions,
    cast,
    setValues,
}) => {
    const theme = useTheme();

    const onSelect = (
        selectedList: QuestionAnswerCast[],
        questionId: number
    ) => {
        setValues(selectedList, questionId);
    };

    return (
        <CardStyled className="mb-3">
            <h3 className="text-center mt-3">Challenge Questions</h3>
            {questions &&
                questions.map((question, index) => {
                    return (
                        <Card.Body key={index}>
                            <FormGroupWrapper
                                style={
                                    index % 2 === 1
                                        ? {
                                              backgroundColor: theme.primary,
                                              position: "relative",
                                          }
                                        : { position: "relative" }
                                }
                                className="pt-3 pb-3 mb-0 pr-2 pl-2 align-items-center"
                                as={Row}
                            >
                                <Col sm={6} xs={6}>
                                    {question.description}
                                </Col>
                                <Col sm={6} xs={6}>
                                    <Multiselect
                                        avoidHighlightFirstOption
                                        style={{
                                            searchBox: {
                                                color: theme.textColor,
                                            },
                                            inputField: {
                                                color: theme.textColor,
                                            },
                                            chips: {
                                                background: theme.secondary,
                                            },
                                            multiselectContainer: {
                                                color: theme.textColor,
                                                background: theme.primary,
                                            },
                                            optionContainer: {
                                                background: theme.primary,
                                            },
                                            option: {
                                                color: theme.textColor,
                                            },
                                        }}
                                        options={cast} // Options to display in the dropdown
                                        selectedValues={question.answers} // Preselected value to persist in dropdown
                                        onSelect={(
                                            selectedList: QuestionAnswerCast[],
                                            selectedItem: {
                                                id: number;
                                                name: string;
                                                eliminated: boolean;
                                                episode_eliminated:
                                                    | null
                                                    | number;
                                            }
                                        ) => {
                                            onSelect(
                                                selectedList,
                                                question.question_id
                                            );
                                        }} // Function will trigger on select event
                                        onRemove={(
                                            selectedList: QuestionAnswerCast[],
                                            removedItem: {
                                                id: number;
                                                name: string;
                                                eliminated: boolean;
                                                episode_eliminated:
                                                    | null
                                                    | number;
                                            }
                                        ) => {
                                            onSelect(
                                                selectedList,
                                                question.question_id
                                            );
                                        }} // Function will trigger on remove event
                                        displayValue="name" // Property name to display in the dropdown options
                                    />
                                </Col>
                            </FormGroupWrapper>
                        </Card.Body>
                    );
                })}
        </CardStyled>
    );
};

export default LogQuestionsAccordion;

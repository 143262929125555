import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import useQuery from "../../hooks/useQuery";
import { UserModel } from "../../models/User";
import { Subscription } from "../../models/Subscriptions";
import { login } from "../../store/user/actions";
import { RootState } from "../../store";

import PriceCard from "../atoms/PriceCard";
import StripeModal from "../molecules/StripeModal";
import PageTemplate from "../templates/PageTemplate";

interface ParamTypes {
    id: string;
}

const AddCustomChallengeQuestions: React.FC = () => {
    const dispatch = useDispatch();
    const query = useQuery();
    const { id } = useParams<ParamTypes>();
    const history = useHistory();
    const isLoggedIn = useSelector((state: RootState) => state.user);
    const leagueName = query.get("leagueName") ? query.get("leagueName") : "";
    const template = query.get("template") ? query.get("template") : "";
    const userToken = query.get("token") ? query.get("userToken") : "";
    const [showStripeModal, setShowStripeModal] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>("Add Custom Scoring");
    const [amount, setAmount] = useState<number>();
    const [challengeQuestions, setChallengeQuestions] = useState<number>();
    const [submitting, setSubmitting] = useState<boolean>(false);

    const elements = useElements();
    const stripe = useStripe();

    const addCustomChallengeQuestionsRequest = useApi(
        apiRoutes.ADD_CUSTOM_CHALLENGE_QUESTIONS(id),
        {
            responseKey: "message",
            onSuccess: (message: string) => {
                if (template === "blank") {
                    toast.success(
                        message +
                            " Please reload your league to edit custom challenge questions. Either close the app and reopen, or navigate out of your league and then back in."
                    );
                } else {
                    toast.success(message);
                }
                setSubmitting(false);
                history.push(`/custom/questions/edit/${id}`);
            },
            onFailure: (message: string) => {
                setSubmitting(false);
            },
        }
    );
    const paymentIntentRequest = useApi(apiRoutes.GET_PAYMENT_INTENT(), {
        responseKey: "intent",
        onSuccess: async (response: { client_secret: string }) => {
            const clientSecret = response.client_secret;

            const { error } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                },
            });

            if (error) {
                toast.error(error.message);
                setSubmitting(false);
            } else {
                addCustomChallengeQuestionsRequest.request({
                    amount: challengeQuestions,
                });
            }
        },
        onFailure: () => {
            setSubmitting(false);
        },
    });
    const getUserByTokenRequest = useApi(apiRoutes.GET_USER_BY_TOKEN(), {
        onSuccess: (response: {
            user: UserModel;
            subscription: Subscription;
        }) => {
            dispatch(login({ ...response.user, isLoggedIn: true }));
        },
    });

    useEffect(() => {
        if (!isLoggedIn && userToken.length > 0) {
            getUserByTokenRequest.request({ token: userToken });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    const handleSubmit = async (
        email: string,
        amount: number,
        description: string
    ) => {
        setSubmitting(true);
        if (!stripe || !elements) {
            toast.error("There was an issue submitting payment");
            setSubmitting(false);
            return;
        }

        paymentIntentRequest.request({ email, amount, description });
    };

    return (
        <PageTemplate
            header="Add Custom Challenge Questions"
            headerSecondary={leagueName}
            bareContent
        >
            <Row className="justify-content-center">
                <PriceCard
                    title="1 Challenge Question"
                    price={1}
                    listGroup={[
                        "1 custom challenge question that you keep track of throughout the season",
                    ]}
                    buttonText="Checkout"
                    onButtonPress={() => {
                        setAmount(1);
                        setChallengeQuestions(1);
                        setModalTitle("1 Challenge Question");
                        setShowStripeModal(true);
                    }}
                />
                <PriceCard
                    badge="Save 40%"
                    title="5 Challenge Questions"
                    price={3}
                    listGroup={[
                        "5 custom challenge questions that you keep track of throughout the season",
                    ]}
                    buttonText="Checkout"
                    onButtonPress={() => {
                        setAmount(3);
                        setChallengeQuestions(5);
                        setModalTitle("5 Scoring Fields");
                        setShowStripeModal(true);
                    }}
                />
                <PriceCard
                    badge="Save 50%"
                    title="10 Challenge Questions"
                    price={5}
                    listGroup={[
                        "10 custom challenge questions that you keep track of throughout the season",
                    ]}
                    buttonText="Checkout"
                    onButtonPress={() => {
                        setAmount(5);
                        setChallengeQuestions(10);
                        setModalTitle("10 Scoring Fields");
                        setShowStripeModal(true);
                    }}
                />
            </Row>
            <div className="text-center">
                <Link to={`/myleagues/view/${id}`}>
                    <Button variant="light">Cancel</Button>
                </Link>
            </div>
            <StripeModal
                showModal={showStripeModal}
                setShowModal={setShowStripeModal}
                title={modalTitle}
                onSubmit={handleSubmit}
                amount={amount}
                submitting={submitting}
            />
        </PageTemplate>
    );
};

export default AddCustomChallengeQuestions;
